import React from 'react';
import { Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';

import { publicApi } from 'store/api';
import { getOrderItems } from 'store/slices/order';

import { IAdditionalServiceOrder, IRentalItem, ITimeSlot } from 'types/types';
import { useSafeParams } from 'hooks/useSafeParams';
import AppSpinner from 'components/ui-kit/AppSpinner/AppSpinner';
import { PUBLIC_BOAT } from 'constants/environment';

import { CrossIcon } from 'assets/images';

import { ServiceCount, Root } from './OrderTimeDetails.styles';

interface IOrderTimeDetailsProps {
  className?: string;
  services: IRentalItem[];
  slots: ITimeSlot[];
  additionalServiceOrders: IAdditionalServiceOrder[];
}

export const OrderTimeDetails = ({
  className,
  services,
  slots,
  additionalServiceOrders,
}: IOrderTimeDetailsProps) => {
  const { t } = useTranslation();

  const { publicId } = useSafeParams();
  const {
    data: totalSetTime,
    isFetching: timeFetching,
    isError: timeError,
  } = publicApi.useGetTotalSetTimeQuery(
    publicId && slots.length > 0 && services.length > 0
      ? {
          public_id: publicId,
          orders: getOrderItems({
            services,
            slots,
            additionalServiceOrders,
          }),
        }
      : skipToken,
  );

  const canShowSet =
    slots.length > 0 && services.length > 0 && totalSetTime && !timeError;

  const boatText = publicId === PUBLIC_BOAT
    ? t('BOAT', { count: services.length })
    : t('SERVICE', { count: services.length });

  return (
    <Root className={className}>
      <Typography
        component="span"
        fontSize={16}
        fontWeight={600}
        color="text.secondary">
        {slots.length > 0
          ? t('SET', { count: slots.length })
          : boatText}
      </Typography>

      {timeFetching ? (
        <AppSpinner size={20} />
      ) : canShowSet ? (
        <>
          <Typography
            component="span"
            fontSize={16}
            color="text.tertiary"
            marginLeft="8px">
            ({totalSetTime.data.result.total_set_time} {t('MIN')})
          </Typography>

          <ServiceCount>
            <CrossIcon width={14} height={14} />
            <Typography
              component="span"
              fontSize={12}
              fontWeight={500}
              lineHeight={1}
              color="text.tertiary"
              marginLeft="2px">
              {boatText}
            </Typography>
          </ServiceCount>
        </>
      ) : null}
    </Root>
  );
};
