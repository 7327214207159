import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import { skipToken } from '@reduxjs/toolkit/query';
import { toast } from 'react-toastify';

import { privateApi, publicApi } from 'store/api';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { bookSlice, bookGetOrderItemsWithServices } from 'store/slices/book';

import { useForm } from 'hooks/useForm';
import { ISelectOption } from 'components/app-selects';
import { useSafeParams } from 'hooks/useSafeParams';
import { SelectSlot } from 'features/slot';
import {
  IAdditionalServiceOrder,
  IAvailableEmployees,
  IRentalItem,
  ITimeSlot,
} from 'types/types';
import { IAdditionalServiceForm } from 'features/additionalService/AdditionalService/schema';
import { assertIsDefined } from 'utils/assertion';
import { isApiError, isFetchBaseQueryError } from 'utils/network';
import AppSpinner from 'components/ui-kit/AppSpinner/AppSpinner';
import { IBookTimeSlot, ICancelOrder } from 'types/admin';
import { API_BASE_URL } from 'constants/environment';
import { ResultPrice } from 'features/resultStep/ResultPrice';
import { IResultStepOrderItem } from 'features/resultStep/types';
import { isAdminRoute } from 'features/admin/utils/isAdmin';
import { hasAdminToken } from 'utils/token';
import { isLiteralObject } from 'utils/isLiteralObject';
import { AppDrawer } from 'components/app-modals';
import useToggle from 'hooks/useToggle';

import { DeleteIcon } from 'assets/images';
import { PlusIcon } from 'assets/images';

import {
  ClientForm,
  ClientInformationInAdminSchema,
  TClientInformationInAdmin,
} from '../components/ClientForm';
import { StepAction } from '../components/StepAction';
import { ServiceSettings } from '../components/ServiceSettings';
import { PaymentTypeForm } from '../components/PaymentTypeForm';
import { PaymentStatus } from '../components/PaymentStatus';

import {
  RootHeader,
  Root,
  RootContent,
  ServiceItem,
  AddSetBtn,
  CenterContainer,
  StyledDeleteButton,
  Statuses,
} from './EditBook.style';
import { useEditBookSearchParams } from './useEditBookSearchParams';
import { ServiceOrderInfo } from './ServiceOrderInfo';
import { CancelOrderDrawer } from './CancelOrderDrawer';

export const EditBook = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { rentalOrderId } = useEditBookSearchParams();
  const { publicId } = useSafeParams();

  const {
    data: rentalData,
    isLoading,
    isError: isRentalReadError,
    error: rentalReadError,
  } = privateApi.useRentalVisitsReadQuery(
    rentalOrderId && publicId
      ? {
          public_id: publicId,
          rental_order_id: rentalOrderId,
        }
      : skipToken,
  );

  const { data: unitedData } = publicApi.useGetUnitedDataQuery(
    publicId
      ? {
          public_id: publicId,
        }
      : skipToken,
  );

  const allServicesData = unitedData?.data.rental_items ?? [];

  const allServices = rentalData?.data.result.orders
    .map(item => item.rental_item)
    .concat(allServicesData)
    .filter(
      (item, index, self) =>
        index === self.findIndex(elem => elem.id === item.id),
    );

  const servicesIds = useMemo(
    () => unitedData?.data.rental_items.map(({ id }) => id),
    [unitedData?.data.rental_items],
  );

  const selectedSlots = useAppSelector(state => state.book.slots);
  const orderedItemServices = rentalData?.data.result.orders.flatMap(
    order => order.ordered_item_services,
  );

  const paymentTypeVariants: ISelectOption[] = useMemo(() => {
    const paymentTypes = unitedData?.data.project_info.payment_types;

    if (!paymentTypes) {
      return [];
    }

    return paymentTypes.map(item => ({
      value: item.id ?? '',
      label: item.name,
    }));
  }, [unitedData?.data.project_info.payment_types]);

  const clientInfoFormMethods = useForm<TClientInformationInAdmin>({
    schema: ClientInformationInAdminSchema,
    defaultValues: {
      remind: true,
      remindTime: { value: '2', label: t('REMIND_HOUR', { count: 2 }) },
      payment_type:
        paymentTypeVariants.length > 0
          ? paymentTypeVariants.find(item => item.label === 'Онлайн') ||
            paymentTypeVariants[0]
          : undefined,
      notification: {
        email: true,
        sms: false,
      },
    },
  });

  const additionalServiceOrdersFormMethods = useForm<IAdditionalServiceForm>({
    defaultValues: {
      additionalServiceOrders: [],
    },
  });

  const watchAdditionalServiceOrders = additionalServiceOrdersFormMethods.watch(
    'additionalServiceOrders',
  );

  const isInit = useRef(false);

  useEffect(() => {
    if (rentalData && !isInit.current) {
      isInit.current = true;

      const remindTime = isLiteralObject(
        rentalData.data.result.orders[0]?.params,
      )
        ? rentalData.data.result.orders[0]?.params.custom_interval
        : undefined;

      clientInfoFormMethods.reset({
        first_name: rentalData.data.result.user.first_name ?? undefined,
        phone: rentalData.data.result.user.phone ?? undefined,
        email: rentalData.data.result.user.email ?? undefined,
        comment: rentalData.data.result.orders[0]?.comment,
        payment_type: paymentTypeVariants.find(
          payment =>
            payment.value === rentalData.data.result.orders[0]?.payment_type_id,
        ),
        remind: remindTime !== undefined,
        remindTime: remindTime
          ? {
              value: remindTime,
              label: t('REMIND_HOUR', { count: Number(remindTime) }),
            }
          : { value: '2', label: t('REMIND_HOUR', { count: 2 }) },
        notification: {
          email: true,
          sms: false,
        },
      });
    }
  }, [
    additionalServiceOrdersFormMethods,
    clientInfoFormMethods,
    dispatch,
    paymentTypeVariants,
    rentalData,
    t,
  ]);

  useEffect(() => {
    if (!servicesIds) {
      return;
    }

    const additionalServiceOrders: IAdditionalServiceOrder[] = [];
    const slots: IBookTimeSlot[] = [];

    rentalData?.data.result.orders.forEach(item => {
      const availableServices = item.available_item_services?.map(service => {
        return {
          available_count: service?.quantity,
          employees: service.employees,
          item_service_id: service.id,
          parent: service.parent,
          parent_id: service?.parent_id,
          related: service.related,
        };
      });

      slots.push({
        serviceId: item.rental_item_id,
        slot: {
          items: '1',
          is_paid: item.is_paid,
          from: item.from,
          to: item.to,
          slot: item.from.slice(10).trim(),
          day_of_week: '',
          price_multiplier: 1,
          prepaid: false,
          available: true,
          used_items: [],
          available_items: item.ordered_item_service_ids,
          available_services: availableServices,
          serviceId: item.rental_item_id,
        } as any, // TODO: в IMakeOrderServiceItem не хватает полей для ITimeSlot
      });

      item.available_item_services.forEach(service => {
        if (item.ordered_item_service_ids.includes(service.id)) {
          additionalServiceOrders.push({
            serviceId: item.rental_item_id,
            additionalServiceId: service.id,
            slotFrom: item.from,
            count: 1,
            employees: service.employees,
          });
        }
      });
    });

    const availableServices = slots.flatMap(slot => {
      return slot.slot.available_services;
    });

    const orderedItemServicesIds = slots.flatMap(slot => {
      return slot.slot.available_items;
    });

    const updatedSlots = slots.map(slot => {
      return {
        ...slot,
        slot: {
          ...slot.slot,
          available_services: slot.slot.available_services.map(item => {
            const matchingService = availableServices.find(
              service => service.item_service_id === item.item_service_id,
            );

            if (matchingService) {
              const availableEmployees: IAvailableEmployees[] =
                matchingService?.employees
                  ? matchingService?.employees?.map(employee => ({
                      userId: employee.public_user_id,
                      slotFrom: slot.slot.from,
                      serviceId: '',
                      isAvailable: true,
                    }))
                  : [];

              const formattedEmployees = servicesIds?.flatMap(serviceId => {
                return availableEmployees.map(employee => {
                  return { ...employee, serviceId };
                });
              });

              return {
                ...item,
                available_employees: formattedEmployees,
              };
            }
            return item;
          }),
        },
      };
    });

    const modifiedSlots = updatedSlots.map(slot => {
      return {
        ...slot,
        slot: {
          ...slot.slot,
          available_services: slot.slot.available_services.map(item => {
            if (orderedItemServicesIds.includes(item.item_service_id)) {
              return {
                ...item,
                available_employees: item?.available_employees?.map(
                  employee => {
                    if (employee.serviceId === slot.serviceId) {
                      return {
                        ...employee,
                        isAvailable: true,
                      };
                    } else {
                      return { ...employee, isAvailable: false };
                    }
                  },
                ),
              };
            }

            return item;
          }),
        },
      };
    });

    dispatch(bookSlice.actions.setSlots(modifiedSlots));
    dispatch(bookSlice.actions.setAdditionalService(modifiedSlots));

    additionalServiceOrdersFormMethods.setValue(
      'additionalServiceOrders',
      additionalServiceOrders,
    );
  }, [servicesIds, dispatch, rentalData]);

  const [serviceSlotSelection, setServiceSlotSelection] = useState<
    IRentalItem | undefined
  >(undefined);

  const onSelectSlot = useCallback(
    (service: IRentalItem) => (serviceSlots: ITimeSlot[]) => {
      const otherServices = selectedSlots.filter(
        slot => slot.serviceId !== service.id,
      );

      const slots = [
        ...otherServices,
        ...serviceSlots.map(slot => ({
          serviceId: service.id,
          slot,
        })),
      ];

      const availableServices = slots
        .flatMap(slot => {
          return slot.slot.available_services;
        })
        .filter((service, index, self) => {
          return (
            self.findIndex(
              s => s.item_service_id === service.item_service_id,
            ) === index
          );
        });

      const modifiedSlots = slots.map(slot => {
        if (slot.serviceId !== service.id) {
          return slot;
        }

        return {
          ...slot,
          slot: {
            ...slot.slot,
            available_services: slot.slot.available_services.map(item => {
              const matchingService = availableServices.find(
                service => service.item_service_id === item.item_service_id,
              );

              if (matchingService) {
                const availableEmployees: IAvailableEmployees[] =
                  matchingService.employees
                    ? matchingService.employees.map(employee => ({
                        userId: employee.public_user_id,
                        slotFrom: slot.slot.from,
                        serviceId: '',
                        isAvailable: true,
                      }))
                    : [];

                const formattedEmployees = servicesIds?.flatMap(serviceId => {
                  return availableEmployees.map(employee => {
                    return { ...employee, serviceId };
                  });
                });

                return {
                  ...item,
                  available_employees:
                    matchingService?.available_employees?.find(
                      item => !item.isAvailable,
                    )
                      ? matchingService.available_employees
                      : formattedEmployees,
                };
              }
              return item;
            }),
          },
        };
      });

      dispatch(bookSlice.actions.setSlots(modifiedSlots));
      setServiceSlotSelection(undefined);
    },
    [selectedSlots, dispatch],
  );

  const onRemoveSlot = useCallback(
    (slot: IBookTimeSlot) => {
      dispatch(bookSlice.actions.removeSlot(slot));

      const additionalServiceOrders =
        additionalServiceOrdersFormMethods.getValues('additionalServiceOrders');

      additionalServiceOrdersFormMethods.setValue(
        'additionalServiceOrders',
        additionalServiceOrders.filter(order => {
          if (order.serviceId === slot.serviceId) {
            return order.slotFrom !== slot.slot.from;
          } else {
            return true;
          }
        }),
      );
    },
    [additionalServiceOrdersFormMethods, dispatch],
  );

  const onRemoveAdditionalService = useCallback(
    (slot: IBookTimeSlot) => {
      dispatch(bookSlice.actions.removeAdditionalService(slot));

      const additionalServiceOrders =
        additionalServiceOrdersFormMethods.getValues('additionalServiceOrders');

      additionalServiceOrdersFormMethods.setValue(
        'additionalServiceOrders',
        additionalServiceOrders.filter(
          order => order.additionalServiceId !== slot.additionalServiceId,
        ),
      );
    },
    [additionalServiceOrdersFormMethods, dispatch],
  );

  const paymentPageUrl = `${API_BASE_URL}/pay?rental_order_id=${rentalOrderId}`;

  const isPermissionCancel =
    isAdminRoute() && hasAdminToken()
      ? false
      : !rentalData?.data.result.orders[0].cancelable;
  const isPaid = rentalData?.data.result.orders[0].is_paid === '1';
  const totalAmount = rentalData?.data.result.total_amount;

  // Используется когда isPaid = true
  const orderedServices = useMemo(() => {
    if (!rentalData) {
      return [];
    }

    const orderItems = rentalData?.data.result.orders.reduce(
      (res, item) => {
        if (res[item.rental_item_id]) {
          res[item.rental_item_id].slots.push(item);
        } else {
          res[item.rental_item_id] = {
            service: item.rental_item,
            slots: [item],
          };
        }

        return res;
      },
      {} as Record<string, IResultStepOrderItem>,
    );

    return Object.values(orderItems);
  }, [rentalData]);

  const [rentalVisitUpdate] = privateApi.useRentalVisitsUpdateMutation();
  const onSubmit = useCallback(
    async (values: TClientInformationInAdmin) => {
      try {
        assertIsDefined(publicId);
        assertIsDefined(rentalOrderId);

        const additionalServiceOrders =
          additionalServiceOrdersFormMethods.getValues(
            'additionalServiceOrders',
          );

        await rentalVisitUpdate({
          public_id: publicId,
          rental_order_id: rentalOrderId,
          email: values.email,
          first_name: values.first_name,
          last_name: values.last_name ?? '',
          comment: values.comment,
          phone: values.phone,
          payment_type_id: values.payment_type.value,
          orders: bookGetOrderItemsWithServices({
            slots: selectedSlots,
            additionalServiceOrders,
            remindTime: values.remind ? values.remindTime?.value : undefined,
          }),
        }).unwrap();

        toast.success(t('BOOK_UPDATED'), {
          position: 'bottom-center',
          autoClose: false,
        });
      } catch (e) {
        if (isFetchBaseQueryError(e) && isApiError(e.data)) {
          toast.error(e.data.error?.message, {
            position: 'bottom-center',
            autoClose: false,
          });
        } else {
          toast.error(t('BOOK_ERROR'), {
            position: 'bottom-center',
            autoClose: false,
          });
        }
      }
    },
    [
      additionalServiceOrdersFormMethods,
      publicId,
      rentalVisitUpdate,
      rentalOrderId,
      selectedSlots,
      t,
    ],
  );

  const [isCanceled, setIsCanceled] = useState(false);
  const [cancelResult, setCancelResult] = useState<ICancelOrder | null>(null);
  const isAlreadyOrderCanceled = Boolean(
    rentalData?.data.result.orders[0].dt_deleted,
  );
  const [showCancelModal, toggleCancelModal] = useToggle(false);

  if (isAlreadyOrderCanceled || isCanceled) {
    return (
      <Root>
        <CenterContainer>
          <CancelOrderDrawer
            isOrderCanceled
            rentalOrderId={rentalOrderId as string}
            cancelResult={cancelResult}
            onCanceled={result => {
              setIsCanceled(true);
              setCancelResult(result);
            }}
            onClose={toggleCancelModal}
          />
        </CenterContainer>
      </Root>
    );
  }

  if (isRentalReadError || isRentalReadError) {
    const error = rentalReadError ?? rentalReadError;

    return (
      <Root>
        <CenterContainer>
          <Typography fontSize={16} color="text.error">
            {isFetchBaseQueryError(error) && isApiError(error.data)
              ? error.data.error?.message
              : t('ORDER_INFO_ERROR')}
          </Typography>
        </CenterContainer>
      </Root>
    );
  }

  if (isLoading) {
    return (
      <Root>
        <CenterContainer>
          <AppSpinner />
        </CenterContainer>
      </Root>
    );
  }

  if (serviceSlotSelection) {
    return (
      <Root>
        <SelectSlot
          onBackClick={() => setServiceSlotSelection(undefined)}
          defaultSlots={selectedSlots
            .filter(slot => slot.serviceId === serviceSlotSelection.id)
            .map(slot => slot.slot)}
          onSubmit={onSelectSlot(serviceSlotSelection)}
          services={[serviceSlotSelection]}
          canSubmitEmpty
        />
      </Root>
    );
  }

  return (
    <Root onSubmit={clientInfoFormMethods.handleSubmit(onSubmit)}>
      <RootContent>
        <RootHeader>
          <Typography
            component="h2"
            fontSize={20}
            fontWeight={600}
            color="text.secondary">
            {t('CLIENT_INFO')}
          </Typography>
        </RootHeader>
        <Statuses>
          <PaymentStatus status={isPaid ? 'paid' : 'no-paid'} />
        </Statuses>

        <FormProvider {...clientInfoFormMethods}>
          <ClientForm paymentTypes={paymentTypeVariants} />
        </FormProvider>

        <Typography
          component="h3"
          fontSize={20}
          fontWeight={600}
          color="text.secondary"
          textAlign="center"
          marginTop="30px"
          marginBottom="20px">
          {t('DETAILS')}
        </Typography>

        {isPaid ? (
          <>
            {isLoading ? (
              <AppSpinner className="edit-book__spinner" />
            ) : (
              <>
                {orderedServices.map(orderService => (
                  <ServiceItem key={orderService.service!.id}>
                    <ServiceOrderInfo
                      serviceOrderItem={orderService}
                      additionalServices={orderedItemServices ?? []}
                    />
                  </ServiceItem>
                ))}

                <ResultPrice
                  className="edit-book__result-price"
                  totalCost={Number(totalAmount)}
                />
              </>
            )}
          </>
        ) : (
          <FormProvider {...additionalServiceOrdersFormMethods}>
            {allServices &&
              allServices.map(service => (
                <ServiceItem key={service.id}>
                  <ServiceSettings
                    servicesIds={allServices.map(item => item.id)}
                    additionalServiceOrders={watchAdditionalServiceOrders.filter(
                      order => order.serviceId === service.id,
                    )}
                    additionalServices={orderedItemServices ?? []}
                    slots={selectedSlots.filter(
                      slot => slot.serviceId === service.id,
                    )}
                    service={service}
                    onRemoveSlot={onRemoveSlot}
                    onRemoveAdditionalService={onRemoveAdditionalService}
                    isEditBook
                  />
                  <AddSetBtn
                    size="l"
                    filling="filled"
                    RightIcon={PlusIcon}
                    onClick={() => setServiceSlotSelection(service)}>
                    {t('ADD_SET')}
                  </AddSetBtn>
                </ServiceItem>
              ))}
          </FormProvider>
        )}

        <FormProvider {...clientInfoFormMethods}>
          <PaymentTypeForm
            orders={orderedServices}
            orderInfoLoading={isLoading}
            paymentPageUrl={paymentPageUrl}
            paymentTypes={paymentTypeVariants}
          />
        </FormProvider>

        <StyledDeleteButton
          filling="ascetic"
          RightIcon={DeleteIcon}
          onClick={toggleCancelModal}
          type="button"
          disabled={isPermissionCancel}>
          Удалить визит
        </StyledDeleteButton>
      </RootContent>

      <AppDrawer
        anchor="bottom"
        onClose={toggleCancelModal}
        open={showCancelModal}
        disableEnforceFocus
        ModalProps={{
          disableScrollLock: true,
        }}>
        <CancelOrderDrawer
          cancelResult={cancelResult}
          onCanceled={result => {
            setIsCanceled(true);
            setCancelResult(result);
          }}
          rentalOrderId={rentalOrderId as string}
          onClose={toggleCancelModal}
        />
      </AppDrawer>

      <StepAction
        disabled={selectedSlots.length === 0}
        isLoading={clientInfoFormMethods.formState.isSubmitting}
        type="submit"
        label={t('SAVE')}
      />
    </Root>
  );
};
