import React, { useMemo, useState, useEffect, Fragment } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { skipToken } from '@reduxjs/toolkit/query';
import { useFormContext } from 'react-hook-form';

import { publicApi } from 'store/api';
import { useAppDispatch } from 'store/hooks';
import { orderSlice } from 'store/slices/order';

import { IBookTimeSlot } from 'types/admin.ts';
import { IRentalItem, ITimeSlot } from 'types/types';
import { useDateLocale } from 'i18n/useDateLocale';
import { parseApiDate } from 'utils/parseApiDate';
import AppSpinner from 'components/ui-kit/AppSpinner/AppSpinner';
import { useSafeParams } from 'hooks/useSafeParams';
import { TimeRange } from 'components/TimeRange/TimeRange';
import AppBtn from 'components/ui-kit/AppBtn/AppBtn';
import { IAdditionalServicesResult } from 'types/types.ts';
import AppAmount from 'components/ui-kit/AppAmount/AppAmount';

import { ChevronUpIcon, CrossIcon, PlusIcon } from 'assets/images';

import { SelectAdditionalService } from '../../SelectAdditionalService';
import { IAdditionalServiceForm } from '../schema';

import {
  Root,
  AdditionalAddBtn,
  StyledSlot,
  StyledSlotNum,
  StyledSlots,
  SlotRemoveBtn,
} from './AdditionalServiceSettings.styles';

interface IAdditionalServiceSettingsProps {
  className?: string;
  service: IRentalItem;
  slots: ITimeSlot[];
  servicesIds: string[];
  editedAdditionalServices?: IAdditionalServicesResult[];
  onRemoveSlot?: (slot: ITimeSlot) => void;
  onRemoveAdditionalService?: (slot: IBookTimeSlot) => void;
  isEditBook?: boolean;
}

export const AdditionalServiceSettings = ({
  className,
  service,
  slots,
  servicesIds,
  editedAdditionalServices,
  isEditBook = false,
  onRemoveSlot,
  onRemoveAdditionalService,
}: IAdditionalServiceSettingsProps) => {
  const { t } = useTranslation();
  const locale = useDateLocale();
  const dispatch = useAppDispatch();

  const { getValues } = useFormContext<IAdditionalServiceForm>();
  const hasInitialSelected = useMemo(
    () =>
      getValues('additionalServiceOrders').findIndex(
        item => item.serviceId === service.id,
      ) !== -1,
    [getValues, service.id],
  );

  const [openSelection, setOpenSelection] = useState(hasInitialSelected);

  const { publicId } = useSafeParams();
  const { data: additionalServices, isLoading } =
    publicApi.useGetItemServicesQuery(
      publicId
        ? {
            public_id: publicId,
            available: JSON.stringify(
              slots ? slots.map(slot => slot.from) : [],
            ),
          }
        : skipToken,
    );

  useEffect(() => {
    if (!additionalServices || !servicesIds) {
      return;
    }

    if (!isEditBook) {
      const modifiedSlots = slots.map(slot => {
        const updatedSlot = { ...slot };

        updatedSlot.available_services = updatedSlot.available_services.map(
          item => {
            const matchingService = additionalServices.data.result.find(
              service => service.id === item.item_service_id,
            );

            if (matchingService) {
              const availableEmployees = matchingService?.employees
                ? matchingService?.employees.map(employee => ({
                    userId: employee.public_user_id,
                    slotFrom: slot.from,
                    serviceId: '',
                    isAvailable: true,
                  }))
                : [];

              const formattedEmployees = servicesIds.flatMap(serviceId => {
                return availableEmployees.map(employee => {
                  return { ...employee, serviceId };
                });
              });

              return {
                ...item,
                available_employees: formattedEmployees,
              };
            }
            return item;
          },
        );
        return updatedSlot;
      });

      dispatch(orderSlice.actions.setSlots(modifiedSlots));
      return;
    }
  }, [additionalServices, isEditBook]);

  return (
    <Root className={className}>
      <StyledSlots>
        {slots.map((slot, index) => (
          <Fragment key={slot.from + index}>
            {isEditBook && (
              <Typography
                component="span"
                fontSize={16}
                fontWeight={500}
                color="text.tertiary"
                textAlign="center">
                {format(parseApiDate(slot.from), 'd MMMM yyyy, EEEEEE', {
                  locale,
                })}
              </Typography>
            )}

            <StyledSlot>
              <StyledSlotNum>{index + 1}</StyledSlotNum>
              <span>
                <TimeRange slot={slot} />
              </span>
              {!isEditBook && (
                <Typography
                  component="span"
                  fontSize={16}
                  fontWeight={500}
                  color="text.tertiary"
                  marginLeft="auto">
                  {format(parseApiDate(slot.from), 'd MMMM yyyy, EEEEEE', {
                    locale,
                  })}
                </Typography>
              )}
              {isEditBook && (
                <AppAmount
                  className="additional-service-settings__price"
                  amount={Number(slot.price)}
                />
              )}
              {onRemoveSlot ? (
                <SlotRemoveBtn
                  onClick={() => onRemoveSlot(slot)}
                  size="small"
                  aria-label="remove">
                  <CrossIcon />
                </SlotRemoveBtn>
              ) : null}
            </StyledSlot>

            {isEditBook &&
              editedAdditionalServices &&
              slot.available_items.map(item => {
                const filteredEditedAdditionalServices =
                  editedAdditionalServices.filter(
                    ({ item_service_id, from }) =>
                      item_service_id === item && from === slot.from,
                  );

                return filteredEditedAdditionalServices.map(item => (
                  <div
                    className="additional-service-settings__item"
                    key={item.from + item.to}>
                    <div className="additional-service-settings__name">
                      {item.name}
                    </div>

                    <AppAmount
                      className="additional-service-settings__price"
                      amount={Number(item.price)}
                    />
                    {onRemoveAdditionalService ? (
                      <SlotRemoveBtn
                        onClick={() =>
                          onRemoveAdditionalService({
                            serviceId: service.id,
                            slot: slot,
                            additionalServiceId: item.item_service_id,
                          })
                        }
                        size="small"
                        aria-label="remove">
                        <CrossIcon />
                      </SlotRemoveBtn>
                    ) : null}
                  </div>
                ));
              })}
          </Fragment>
        ))}
      </StyledSlots>
      {openSelection ? (
        <div className="select-additional-service">
          {isLoading ? (
            <AppSpinner />
          ) : (
            <>
              <SelectAdditionalService
                service={service}
                additionalServices={additionalServices?.data.result ?? []}
                slots={slots}
                isEditBook={isEditBook}
              />
              <AppBtn
                className="hide-additional-service"
                fullWidth
                size="l"
                filling="ascetic"
                RightIcon={ChevronUpIcon}
                onClick={() => setOpenSelection(false)}>
                {t('HIDE_ADDITIONAL_SERVICES')}
              </AppBtn>
            </>
          )}
        </div>
      ) : slots.length > 0 ? (
        <AdditionalAddBtn
          size="l"
          filling="filled"
          RightIcon={PlusIcon}
          onClick={() => setOpenSelection(true)}>
          {t('ADD_ADDITIONAL_SERVICES')}
        </AdditionalAddBtn>
      ) : null}
    </Root>
  );
};
