import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

import AppBtn from 'components/ui-kit/AppBtn/AppBtn';

export const Root = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    .select-additional-service {
      margin-top: 10px;
    }

    .hide-additional-service {
      margin-top: 14px;
    }

    .additional-service-settings__item {
      display: flex;
      width: 100%;
      gap: 10px;
      align-items: center;
      justify-content: space-between;
      margin: 5px 0;
      color: ${theme.palette.text.secondary};
    }
    
    .additional-service-settings__name {
      margin-left: 40px;
    }

    .additional-service-settings__price {
      font-weight: 600;
      margin-left: auto;
    }
  `,
);

export const StyledSlots = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  `,
);

export const StyledSlot = styled.div(
  () => css`
    display: flex;
    align-items: center;
    gap: 10px;

    & + & {
      margin-top: 10px;
    }

  `,
);

export const StyledSlotNum = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.palette.background.neutral};
    border-radius: 50%;
    min-width: 33px;
    width: 33px;
    height: 33px;
  `,
);

export const AdditionalAddBtn = styled(AppBtn)(
  () => css`
    margin-top: 10px;
  `,
);

export const SlotRemoveBtn = styled(IconButton)(
  ({ theme }) => css`
    margin-left: 10px;
    background-color: ${theme.palette.background.neutral};
  `,
);
