import React from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { Typography } from '@mui/material';

import { publicApi } from 'store/api/public.ts';
import { bookGetOrderItems } from 'store/slices/book.ts';

import { IAdditionalServiceOrder, IRentalItem } from 'types/types.ts';
import { AdditionalServiceSettings } from 'features/additionalService/AdditionalService/AdditionalServiceSettings/AdditionalServiceSettings.tsx';
import { useSafeParams } from 'hooks/useSafeParams.ts';
import { ServiceMiniCard } from 'components/ServiceMiniCard/ServiceMiniCard.tsx';
import AppAmount from 'components/ui-kit/AppAmount/AppAmount.tsx';
import AppSpinner from 'components/ui-kit/AppSpinner/AppSpinner.tsx';
import { IBookTimeSlot } from 'types/admin.ts';
import { IAdditionalServicesResult } from 'types/types.ts';
import { PUBLIC_BOAT } from 'constants/environment';

import { PriceInfo, Root } from './ServiceSettings.styles.tsx';

type TServiceSettingsProps = {
  additionalServiceOrders: IAdditionalServiceOrder[];
  additionalServices?: IAdditionalServicesResult[];
  slots: IBookTimeSlot[];
  onRemoveSlot: (slot: IBookTimeSlot) => void;
  onRemoveAdditionalService?: (slot: IBookTimeSlot) => void;
  service: IRentalItem;
  servicesIds: string[];
  isEditBook: boolean;
};

export const ServiceSettings = (props: TServiceSettingsProps) => {
  const {
    additionalServiceOrders,
    additionalServices,
    service,
    slots,
    onRemoveSlot,
    onRemoveAdditionalService,
    servicesIds,
    isEditBook,
  } = props;
  const { t } = useTranslation();

  const { publicId } = useSafeParams();
  const { data: totalSetTime } = publicApi.useGetTotalSetTimeQuery(
    publicId
      ? {
          public_id: publicId,
          orders: bookGetOrderItems({
            slots,
            additionalServiceOrders,
          }),
        }
      : skipToken,
  );

  const {
    data: totalSetPrice,
    isFetching: priceFetching,
    isError: priceError,
  } = publicApi.useGetTotalSetPriceQuery(
    publicId
      ? {
          public_id: publicId,
          orders: bookGetOrderItems({
            slots,
            additionalServiceOrders,
          }),
        }
      : skipToken,
  );

  const rentTimeText =
    publicId === PUBLIC_BOAT ? 'RENT_TIME_BOAT' : 'RENT_TIME';


  const serviceSlots = slots.map(slot => {
    return {...slot.slot, price: service.price};
  });

  return (
    <Root>
      <ServiceMiniCard
        item={service}
        label={
          totalSetTime?.data.result?.total_set_time
            ? t(rentTimeText, {
                time: totalSetTime?.data.result.total_set_time,
              })
            : ''
        }
      />
      <PriceInfo>
        <Typography fontSize={14} fontWeight={600}>
          {t('PRICE_BOOKING')}
        </Typography>
        {priceFetching ? (
          <span>
            <AppSpinner size={20} />
          </span>
        ) : totalSetPrice && !priceError ? (
          <Typography fontSize={20} fontWeight={600}>
            <AppAmount amount={totalSetPrice.data.result.total_set_price} />
          </Typography>
        ) : null}
      </PriceInfo>
      <AdditionalServiceSettings
        service={service}
        servicesIds={servicesIds}
        slots={serviceSlots}
        editedAdditionalServices={additionalServices}
        onRemoveSlot={slot => {
          const foundSlot = slots.find(item => item.slot.from === slot.from);

          if (foundSlot) {
            onRemoveSlot(foundSlot);
          }
        }}
        onRemoveAdditionalService={onRemoveAdditionalService}
        isEditBook={isEditBook}
      />
    </Root>
  );
};
