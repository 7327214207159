import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { IBookTimeSlot } from 'types/admin';
import {
  IAdditionalServiceOrder,
  IOrderWIthItemServices,
  IOrderItem,
} from 'types/types';
import { parseApiDate } from 'utils/parseApiDate';

export interface IBookState {
  slots: IBookTimeSlot[];
}

const initialState: IBookState = {
  slots: [],
};

export const bookSlice = createSlice({
  name: 'book',
  initialState,
  reducers: {
    setSlots: (state, action: PayloadAction<IBookTimeSlot[]>) => {
      const result = [...action.payload];

      result.sort(
        (a, b) =>
          parseApiDate(a.slot.from).getTime() -
          parseApiDate(b.slot.from).getTime(),
      );

      state.slots = result;
    },
    removeSlot: (state, action: PayloadAction<IBookTimeSlot>) => {
      state.slots = state.slots.filter(slot => {
        if (slot.serviceId === action.payload.serviceId) {
          return slot.slot.from !== action.payload.slot.from;
        } else {
          return true;
        }
      });
    },
    setAdditionalService: (state, action: PayloadAction<IBookTimeSlot[]>) => {
      const result = [...action.payload];

      state.slots = result;
    },
    removeAdditionalService: (state, action: PayloadAction<IBookTimeSlot>) => {
      const { serviceId, slot, additionalServiceId } = action.payload;

      const updatedSlots = state.slots.map(existingSlot => {
        if (existingSlot.serviceId === serviceId && existingSlot.slot.from === slot.from) {

          const filteredIds = slot.available_items.filter(
            elem => elem !== additionalServiceId,
          );

          return {
            ...existingSlot,
            slot: {
              ...existingSlot.slot,
              available_items: filteredIds,
            }
          };
        }
        return existingSlot;
      });

      state.slots = updatedSlots;
    }
  }
});

export default bookSlice.reducer;

/**
 * Соединяет между собой услуги и слоты и создает массив заказов.
 * TODO: объеденить с getOrderItems: изменить везде на IBookTimeSlot (переименовать на универсальный тип)
 */
export const bookGetOrderItems = ({
  slots,
  additionalServiceOrders,
  remindTime,
}: {
  slots: IBookTimeSlot[];
  additionalServiceOrders: IAdditionalServiceOrder[];
  remindTime?: string;
}): IOrderItem[] => {
  const result: IOrderItem[] = [];

  slots.forEach(slot => {
    const additionalServicesIds = additionalServiceOrders
      .filter(
        item =>
          item.serviceId === slot.serviceId && item.slotFrom === slot.slot.from,
      )
      .map(item => item.additionalServiceId);

    result.push({
      is_paid: slot.slot.is_paid,
      rental_item_id: slot.serviceId,
      from: slot.slot.from,
      to: slot.slot.to,
      ordered_item_service_ids: additionalServicesIds,
      comment: undefined,
      params: {
        custom_interval: remindTime,
      },
    });
  });

  return result;
};

export const bookGetOrderItemsWithServices = ({
  slots,
  additionalServiceOrders,
  remindTime,
}: {
  slots: IBookTimeSlot[];
  additionalServiceOrders: IAdditionalServiceOrder[];
  remindTime?: string;
}): IOrderWIthItemServices[] => {
  const result: IOrderWIthItemServices[] = [];

  slots.forEach(slot => {
    const additionalServicesIds = additionalServiceOrders
      .filter(
        item =>
          item.serviceId === slot.serviceId && item.slotFrom === slot.slot.from,
      )
      .map(item => {
        return {
          item_service_id: item.additionalServiceId,
          public_user_id:
            (item?.employees?.length > 0 &&
              item?.employees[0]?.public_user_id) ||
            '0',
        };
      });

    result.push({
      is_paid: slot.slot.is_paid,
      rental_item_id: slot.serviceId,
      from: slot.slot.from,
      to: slot.slot.to,
      ordered_item_services: additionalServicesIds,
      comment: undefined,
      params: {
        custom_interval: remindTime,
      },
    });
  });

  return result;
};
