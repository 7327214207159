/* eslint-disable @typescript-eslint/naming-convention */
import { createTheme, Theme as MuiTheme } from '@mui/material/styles';

import { THEME_SHADOWS } from './theme.constants';

declare module '@emotion/react' {
  interface Theme extends TTheme, MuiTheme {}
}
declare module '@mui/material/styles' {
  interface ThemeOptions extends TTheme {}
}

const PAY2ME_PALETTE = {
  primary: {
    lighter: '#F6F2FF',
    light: '#9D81EF',
    main: '#744CED',
    dark: '#4E22D2',
  },
  secondary: {
    light: '#B9A5F5',
    main: '#9689E1',
    dark: '#715DAE',
  },
  text: {
    primary: '#1F1D1C',
    secondary: '#4A3C73',
    tertiary: '#71758E',
    invertedPrimary: '#F6F9FA',
    accent: '#744CED',
    error: '#DA1D20',
    success: '#27AE60',
    disabled: '#9796A8',
  },
  background: {
    primary: '#FFFFFF',
    secondary: '#FFFFFF',
    neutral: '#F8F8FE',
    invertedNeutral: '#FCFAF9',
    line: '#EBE6FA',
    overlay: '#06095F99',
    default: '#FFFFFF',
  },
  controls: {
    light: '#F2F2F7',
    main: '#E3E2EB',
    dark: '#B9AED9',
    selected: '#E9DFFD',
    disabled: '#E6E7FA',
  },
  error: {
    lighter: '#F9DBDB',
    light: '#F88082',
    main: '#DA1D20',
    dark: '#B7070A',
  },
  success: {
    light: '#E9F7EF',
    main: '#27AE60',
  },
  warning: {
    lighter: '#EEBA1629',
    main: '#EEBA16',
    light: '#FFF6DA',
  },
  info: {
    main: '#2C8CE5',
    light: '#EAF4FC',
  },
  extra: {
    cremeBrulee: '#FEFFE4',
    beige: '#FFEAD6',
    springWater: '#D6E7FF',
    paleOrange: '#F6A468',
    skyBlue: '#85AFEE',
    indianRed: '#5B030C',
    neonGreen: '#B5E550',
    forestGreen: '#1EA689',
    coral: '#FF8C78',
    orange: '#E87920',
  },
  operationHistory: {
    created: '#EEBA16',
    awaited: '#E87920',
    partiallyCompleted: '#B7E36E',
    completed: '#108240',
    sent: '#2C8CE5',
    refunded: '#9887D5',
    err: '#DA1D20',
  },
  gradient: {
    light: 'linear-gradient(57deg, #C4C7FA 27.48%, #E0D6FE 80.93%)',
    main: 'linear-gradient(180deg, #744CED 18.75%, #B650E6 88.02%)',
  },
};

const themeProperties = {
  palette: PAY2ME_PALETTE,

  shadows: THEME_SHADOWS,

  typography: {
    fontFamily: '"Noto Sans", Arial, sans-serif',
  },

  breakpoints: {
    values: {
      xxs: 330,
      xs: 550,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1440,
    },
  },

  widths: {
    page: 1440,
    modalContent: 302,
  },

  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
};

export type TTheme = typeof themeProperties;

export const theme = createTheme(themeProperties);
