import { IBookTimeSlot } from 'types/admin';

export const updatedQuickBookSlots = (
  slots: IBookTimeSlot[],
  serviceId: string,
  additionalServiceId: string,
  isAvailable: boolean,
) => {
  const result = slots.map(slot => {
    const updatedSlot = { ...slot };

    return {
      ...updatedSlot,
      slot: {
        ...updatedSlot.slot,
        available_services: updatedSlot.slot.available_services.map(service => {
          const matchingService =
            service.item_service_id === additionalServiceId;

          if (matchingService) {
            return {
              ...service,
              available_employees: service?.available_employees?.map(
                availableService => {
                  if (
                    availableService.serviceId !== serviceId &&
                    availableService.slotFrom === slot.slot.from
                  ) {
                    return {
                      ...availableService,
                      isAvailable,
                    };
                  }
                  return availableService;
                },
              ),
            };
          }

          return service;
        }),
      },
    };
  });

  return result;
};
