import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import { skipToken } from '@reduxjs/toolkit/query';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Paths } from 'routers/constants';

import { privateApi, publicApi } from 'store/api';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { bookSlice, bookGetOrderItemsWithServices } from 'store/slices/book';

import { useForm } from 'hooks/useForm';
import { ISelectOption } from 'components/app-selects';
import { useSafeParams } from 'hooks/useSafeParams';
import { SelectSlot } from 'features/slot';
import { IAvailableEmployees, IRentalItem, ITimeSlot } from 'types/types';
import { IAdditionalServiceForm } from 'features/additionalService/AdditionalService/schema';
import { assertIsDefined } from 'utils/assertion';
import { isApiError, isFetchBaseQueryError } from 'utils/network';
import { IBookTimeSlot } from 'types/admin';

import { PlusIcon } from 'assets/images';

import {
  ClientForm,
  ClientInformationInAdminSchema,
  TClientInformationInAdmin,
} from '../components/ClientForm';
import { StepAction } from '../components/StepAction';
import { ServiceSettings } from '../components/ServiceSettings';

import {
  RootHeader,
  Root,
  RootContent,
  ServiceItem,
  AddSetBtn,
} from './QuickBook.style';
import { useQuickBookSearchParams } from './useQuickBookSearchParams';

export const QuickBook = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const searchParams = useQuickBookSearchParams();

  const { publicId } = useSafeParams();
  const { data: unitedData } = publicApi.useGetUnitedDataQuery(
    publicId
      ? {
          public_id: publicId,
        }
      : skipToken,
  );

  const allServices = useMemo(
    () => unitedData?.data.rental_items ?? [],
    [unitedData?.data.rental_items],
  );

  const servicesIds = useMemo(
    () => unitedData?.data.rental_items.map(({ id }) => id),
    [unitedData?.data.rental_items],
  );

  const dispatch = useAppDispatch();

  const selectedSlots = useAppSelector(state => state.book.slots);

  const [serviceSlotSelection, setServiceSlotSelection] = useState<
    IRentalItem | undefined
  >(undefined);

  const paymentTypeVariants: ISelectOption[] = useMemo(() => {
    const paymentTypes = unitedData?.data.project_info.payment_types;

    if (!paymentTypes) {
      return [];
    }

    return paymentTypes.map(item => ({
      value: item.id ?? '',
      label: item.name,
    }));
  }, [unitedData?.data.project_info.payment_types]);

  const clientInfoFormMethods = useForm<TClientInformationInAdmin>({
    schema: ClientInformationInAdminSchema,
    defaultValues: {
      first_name: searchParams.first_name ?? undefined,
      phone: searchParams.phone ?? undefined,
      email: searchParams.email ?? undefined,
      comment: searchParams.comment ?? undefined,
      payment_type:
        paymentTypeVariants.length > 0
          ? paymentTypeVariants.find(
              item => item.label === 'Оплата на месте',
            ) || paymentTypeVariants[0]
          : undefined,
      remind: true,
      remindTime: { value: '2', label: t('REMIND_HOUR', { count: 2 }) },
      notification: {
        email: true,
        sms: false,
      },
    },
  });

  const additionalServiceOrdersFormMethods = useForm<IAdditionalServiceForm>({
    defaultValues: {
      additionalServiceOrders: [],
    },
  });

  const watchAdditionalServiceOrders = additionalServiceOrdersFormMethods.watch(
    'additionalServiceOrders',
  );

  const onSelectSlot = useCallback(
    (service: IRentalItem) => (serviceSlots: ITimeSlot[]) => {
      const otherServices = selectedSlots.filter(
        slot => slot.serviceId !== service.id,
      );

      const newSlot = {
        serviceId: service.id,
        slot: serviceSlots[0],
      };

      const slots = [...otherServices, newSlot];

      const availableServices = slots
        .map(slot => {
          return slot.slot.available_services;
        })
        .flat()
        .filter((service, index, self) => {
          return (
            self.findIndex(
              s => s.item_service_id === service.item_service_id,
            ) === index
          );
        });

      const modifiedSlots = slots.map(slot => {
        if (slot.serviceId !== service.id) {
          return slot;
        }

        return {
          ...slot,
          slot: {
            ...slot.slot,
            available_services: slot.slot.available_services.map(item => {
              const matchingService = availableServices.find(
                service => service.item_service_id === item.item_service_id,
              );

              if (matchingService) {
                const availableEmployees: IAvailableEmployees[] =
                  matchingService.employees
                    ? matchingService.employees.map(employee => ({
                        userId: employee.public_user_id,
                        slotFrom: slot.slot.from,
                        serviceId: '',
                        isAvailable: true,
                      }))
                    : [];

                const formattedEmployees = servicesIds?.flatMap(serviceId => {
                  return availableEmployees.map(employee => {
                    return { ...employee, serviceId };
                  });
                });

                return {
                  ...item,
                  available_employees:
                    matchingService?.available_employees?.find(
                      item => !item.isAvailable,
                    )
                      ? matchingService.available_employees
                      : formattedEmployees,
                };
              }
              return item;
            }),
          },
        };
      });

      dispatch(bookSlice.actions.setSlots(modifiedSlots));
      setServiceSlotSelection(undefined);
    },
    [selectedSlots, dispatch],
  );

  const onRemoveSlot = useCallback(
    (slot: IBookTimeSlot) => {
      dispatch(bookSlice.actions.removeSlot(slot));

      const additionalServiceOrders =
        additionalServiceOrdersFormMethods.getValues('additionalServiceOrders');

      additionalServiceOrdersFormMethods.setValue(
        'additionalServiceOrders',
        additionalServiceOrders.filter(order => {
          if (order.serviceId === slot.serviceId) {
            return order.slotFrom !== slot.slot.from;
          } else {
            return true;
          }
        }),
      );
    },
    [additionalServiceOrdersFormMethods, dispatch],
  );

  const [getAvailableSlots] = publicApi.useLazyGetAvailableSlotsQuery();

  useEffect(() => {
    const fetchData = async () => {
      try {
        assertIsDefined(searchParams.rental_item_id);
        assertIsDefined(searchParams.from);
        assertIsDefined(publicId);

        const slots = await getAvailableSlots({
          public_id: publicId,
          items: [searchParams.rental_item_id],
          slot_date: searchParams.from.slice(0, 10),
        });

        const initialSlot = slots.data?.data.result.find(
          slot => slot.from === searchParams.from && slot.available,
        );

        if (!servicesIds) {
          return;
        }

        const updatedSlot = { ...initialSlot };
        updatedSlot.available_services = updatedSlot?.available_services?.map(
          item => {
            const matchingService = updatedSlot.available_services?.find(
              service => service.item_service_id === item.item_service_id,
            );

            if (matchingService) {
              const availableEmployees = item.employees
                ? item.employees.map(employee => ({
                    userId: employee.public_user_id,
                    slotFrom: updatedSlot?.from || '',
                    serviceId: '',
                    isAvailable: true,
                  }))
                : [];

              const formattedEmployees: IAvailableEmployees[] =
                servicesIds.flatMap(serviceId => {
                  return availableEmployees.map(employee => {
                    return { ...employee, serviceId };
                  });
                });

              return {
                ...item,
                available_employees: formattedEmployees,
              };
            }
            return item;
          },
        );

        if (updatedSlot) {
          dispatch(
            bookSlice.actions.setSlots([
              {
                serviceId: searchParams.rental_item_id,
                slot: updatedSlot as unknown as ITimeSlot,
              },
            ]),
          );
        }
      } catch (e) {
        if (isFetchBaseQueryError(e) && isApiError(e.data)) {
          toast.error(e.data.error?.message, {
            position: 'bottom-center',
          });
        } else {
          toast.error(t('BOOK_ERROR'), {
            position: 'bottom-center',
          });
        }
      }
    };

    if (searchParams.rental_item_id && searchParams.from) {
      fetchData();
    }
  }, [
    dispatch,
    getAvailableSlots,
    publicId,
    searchParams.from,
    searchParams.rental_item_id,
    servicesIds,
    t,
  ]);

  const [rentalVisitCreation] = privateApi.useRentalVisitsCreateMutation();

  const onSubmit = useCallback(
    async (values: TClientInformationInAdmin) => {
      try {
        assertIsDefined(publicId);

        const additionalServiceOrders =
          additionalServiceOrdersFormMethods.getValues(
            'additionalServiceOrders',
          );

        const resultCreate = await rentalVisitCreation({
          public_id: publicId,
          email: values.email,
          first_name: values.first_name,
          last_name: values.last_name ?? '',
          comment: values.comment,
          phone: values.phone,
          payment_type_id: values.payment_type.value,
          orders: bookGetOrderItemsWithServices({
            slots: selectedSlots,
            additionalServiceOrders,
            remindTime: values.remind ? values.remindTime?.value : undefined,
          }),
        }).unwrap();

        const rentalOrderId = resultCreate.data.result.rental_order_id;

        navigate(
          `/${publicId}/${Paths.Admin}${Paths.Result}?rental_order_id=${rentalOrderId}`,
          {
            replace: true,
          },
        );
      } catch (e) {
        if (isFetchBaseQueryError(e) && isApiError(e.data)) {
          toast.error(e.data.error?.message, {
            position: 'bottom-center',
          });
        } else {
          toast.error(t('BOOK_ERROR'), {
            position: 'bottom-center',
          });
        }
      }
    },
    [
      additionalServiceOrdersFormMethods,
      navigate,
      publicId,
      rentalVisitCreation,
      selectedSlots,
      t,
    ],
  );

  if (serviceSlotSelection) {
    return (
      <Root>
        <SelectSlot
          onBackClick={() => setServiceSlotSelection(undefined)}
          defaultSlots={selectedSlots
            .filter(slot => slot.serviceId === serviceSlotSelection.id)
            .map(slot => slot.slot)}
          onSubmit={onSelectSlot(serviceSlotSelection)}
          services={[serviceSlotSelection]}
          canSubmitEmpty
        />
      </Root>
    );
  }

  return (
    <Root onSubmit={clientInfoFormMethods.handleSubmit(onSubmit)}>
      <RootContent>
        <RootHeader>
          <Typography
            component="h2"
            fontSize={20}
            fontWeight={600}
            color="text.secondary">
            {t('CLIENT_INFO')}
          </Typography>
        </RootHeader>
        <FormProvider {...clientInfoFormMethods}>
          <ClientForm paymentTypes={paymentTypeVariants} />
        </FormProvider>

        <FormProvider {...additionalServiceOrdersFormMethods}>
          {allServices.map(service => (
            <ServiceItem key={service.id}>
              <ServiceSettings
                servicesIds={allServices.map(item => item.id)}
                additionalServiceOrders={watchAdditionalServiceOrders.filter(
                  order => order.serviceId === service.id,
                )}
                slots={selectedSlots.filter(
                  slot => slot.serviceId === service.id,
                )}
                service={service}
                onRemoveSlot={onRemoveSlot}
                isEditBook
              />
              <AddSetBtn
                size="l"
                filling="filled"
                RightIcon={PlusIcon}
                onClick={() => setServiceSlotSelection(service)}>
                {t('ADD_SET')}
              </AddSetBtn>
            </ServiceItem>
          ))}
        </FormProvider>
      </RootContent>

      <StepAction
        disabled={selectedSlots.length === 0}
        isLoading={clientInfoFormMethods.formState.isSubmitting}
        type="submit"
        label={t('SAVE')}>
        {/* <AppBtn filling="none">{t('CANCEL')}</AppBtn> */}
      </StepAction>
    </Root>
  );
};
