import React, { useCallback, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useFormContext } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { orderSlice } from 'store/slices/order';
import { bookSlice } from 'store/slices/book';

import {
  IAdditionalService,
  IAdditionalServiceOrder,
  IAvailableEmployees,
  ITimeSlot,
} from 'types/types';
import { useDateLocale } from 'i18n/useDateLocale';
import AppCheckbox from 'components/ui-kit/AppCheckbox/AppCheckbox';
import { parseApiDate } from 'utils/parseApiDate';
import { TimeRange } from 'components/TimeRange/TimeRange';
import AppAmount from 'components/ui-kit/AppAmount/AppAmount';
import AppSwitch from 'components/ui-kit/AppSwitch/AppSwitch';
import { isLiteralObject } from 'utils/isLiteralObject';
import AppBtn from 'components/ui-kit/AppBtn/AppBtn';
import { updatedQuickBookSlots } from 'features/admin/utils/updatedQuickBookSlots';

import { CheckMarkIcon, PlusIcon } from 'assets/images';

import { IAdditionalServiceForm } from '../../AdditionalService/schema';
import { getAdditionalServiceCounts } from '../utils';

import { Root, Price, Slots, SlotItem } from './AdditionalServiceForm.styles';
import { toggleModifiedSlots } from './toggleModifiedSlots';

interface IAdditionalServiceFormProps {
  serviceId: string;
  additionalService: IAdditionalService;
  slots: ITimeSlot[];
  isEditBook?: boolean;
}

export const AdditionalServiceForm = ({
  serviceId,
  additionalService,
  slots,
  isEditBook = false,
}: IAdditionalServiceFormProps) => {
  const { t } = useTranslation();
  const locale = useDateLocale();
  const dispatch = useAppDispatch();

  const { setValue, watch } = useFormContext<IAdditionalServiceForm>();

  const additionalServiceOrders = watch('additionalServiceOrders');

  const selected = useMemo(
    () =>
      additionalServiceOrders.filter(
        item =>
          item.serviceId === serviceId &&
          item.additionalServiceId === additionalService.id,
      ),
    [additionalService.id, additionalServiceOrders, serviceId],
  );

  const isAllSelected = useMemo(
    () => selected.length === slots.length,
    [selected.length, slots.length],
  );
  const hasSomeSelected = useMemo(() => selected.length > 0, [selected.length]);

  // Пример "считаемых": Аренда гидрофойла, обед
  const isCountable = additionalService.quantity !== null;

  const isExpandable = useMemo(() => {
    if (
      isLiteralObject(additionalService.params) &&
      additionalService.params.all_sets
    ) {
      return false;
    }

    return true;
  }, [additionalService.params]);

  const [expanded, setExpanded] = useState(isExpandable && hasSomeSelected);

  const quickBookSlots = useAppSelector(state => state.book.slots);

  const updateSlots = (
    additionalService: IAdditionalService,
    serviceId: string,
  ) => {
    const modifiedSlots = slots.map(slot => {
      const updatedAvailableServices = slot.available_services.map(service => {
        const matchingService =
          service.item_service_id === additionalService.id;

        if (matchingService) {
          return {
            ...service,
            available_employees: service?.available_employees?.map(
              availableService => {
                if (
                  availableService.serviceId !== serviceId &&
                  availableService.slotFrom === slot.from
                ) {
                  return {
                    ...availableService,
                    isAvailable: false,
                  };
                }
                return availableService;
              },
            ),
          };
        }

        return service;
      });

      return { ...slot, available_services: updatedAvailableServices };
    });

    if (!isEditBook) {
      dispatch(orderSlice.actions.setSlots(modifiedSlots));
      return;
    }

    const quickBookSlotsUpdated = updatedQuickBookSlots(
      quickBookSlots,
      serviceId,
      additionalService.id,
      false,
    );

    dispatch(bookSlice.actions.setSlots(quickBookSlotsUpdated));
  };

  const revertUpdateSlots = (additionalService: IAdditionalService) => {
    const modifiedSlots = slots.map(slot => {
      const updatedAvailableServices = slot.available_services.map(service => {
        const matchingService =
          service.item_service_id === additionalService.id;

        if (matchingService) {
          return {
            ...service,
            available_employees: service.available_employees?.map(
              availableService => {
                if (
                  availableService.serviceId !== serviceId &&
                  availableService.slotFrom === slot.from
                ) {
                  return {
                    ...availableService,
                    isAvailable: true,
                  };
                }
                return availableService;
              },
            ),
          };
        }

        return service;
      });

      return { ...slot, available_services: updatedAvailableServices };
    });

    if (!isEditBook) {
      dispatch(orderSlice.actions.setSlots(modifiedSlots));
      return;
    }

    const quickBookSlotsUpdated = updatedQuickBookSlots(
      quickBookSlots,
      serviceId,
      additionalService.id,
      true,
    );

    dispatch(bookSlice.actions.setSlots(quickBookSlotsUpdated));
  };

  const onChangeCheckbox = useCallback(
    (slot: ITimeSlot) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setValue('additionalServiceOrders', [
          ...additionalServiceOrders,
          {
            serviceId,
            additionalServiceId: additionalService.id,
            slotFrom: slot.from,
            count: 1,
            employees: additionalService.employees,
          },
        ]);

        const modifiedSlots = toggleModifiedSlots(
          slots,
          additionalService.id,
          slot.from,
          serviceId,
          false,
        );

        if (!isEditBook) {
          dispatch(orderSlice.actions.setSlots(modifiedSlots));
          return;
        }

        const quickBookSlotsUpdated = updatedQuickBookSlots(
          quickBookSlots,
          serviceId,
          additionalService.id,
          false,
        );

        dispatch(bookSlice.actions.setSlots(quickBookSlotsUpdated));
      } else {
        const isMatchOrder = (
          order: IAdditionalServiceOrder,
          slotFrom: string,
        ) =>
          order.serviceId === serviceId &&
          order.additionalServiceId === additionalService.id &&
          order.slotFrom === slotFrom;

        setValue(
          'additionalServiceOrders',
          additionalServiceOrders.filter(item =>
            isMatchOrder(item, slot.from) ? false : true,
          ),
        );

        const modifiedSlots = toggleModifiedSlots(
          slots,
          additionalService.id,
          slot.from,
          serviceId,
          true,
        );

        if (!isEditBook) {
          dispatch(orderSlice.actions.setSlots(modifiedSlots));
          return;
        }

        const quickBookSlotsUpdated = updatedQuickBookSlots(
          quickBookSlots,
          serviceId,
          additionalService.id,
          true,
        );

        dispatch(bookSlice.actions.setSlots(quickBookSlotsUpdated));
      }
    },
    [additionalService.id, additionalServiceOrders, serviceId, setValue],
  );

  const onSelectAll = useCallback(() => {
    const selectAllAvailableSlots = () => {
      const selected = slots.filter(slot => {
        const {
          bookedCount,
          count,
          isAllAvailable,
          isSlotWithEmployeeAvailable,
        } = getAdditionalServiceCounts({
          additionalService,
          additionalServiceOrders,
          serviceId,
          slots: [slot],
        });

        const isAvailableSlot = isCountable
          ? bookedCount < count
          : isSlotWithEmployeeAvailable && isAllAvailable;

        return isAvailableSlot;
      });

      setValue('additionalServiceOrders', [
        ...additionalServiceOrders,
        ...selected.map(slot => ({
          serviceId,
          additionalServiceId: additionalService.id,
          slotFrom: slot.from,
          count: 1,
          employees: additionalService.employees,
        })),
      ]);

      updateSlots(additionalService, serviceId);
    };

    selectAllAvailableSlots();
  }, [additionalService, additionalServiceOrders, serviceId, setValue, slots]);

  const onDeselect = useCallback(() => {
    setValue('additionalServiceOrders', [
      ...additionalServiceOrders.filter(order =>
        order.serviceId === serviceId &&
        order.additionalServiceId === additionalService.id
          ? false
          : true,
      ),
    ]);

    if (isCountable) {
      return;
    }

    revertUpdateSlots(additionalService);
  }, [additionalService.id, additionalServiceOrders, serviceId, setValue]);

  const onToggleSelect = useCallback(() => {
    if (hasSomeSelected) {
      onDeselect();
    } else {
      onSelectAll();

      if (isExpandable) {
        setExpanded(true);
      }
    }
  }, [hasSomeSelected, isExpandable, onDeselect, onSelectAll]);

  const hasAvailableSlots = useMemo(() => {
    const availableSlots = slots.filter(slot => {
      const {
        bookedCount,
        count,
        isAllAvailable,
        isSlotWithEmployeeAvailable,
      } = getAdditionalServiceCounts({
        additionalService,
        additionalServiceOrders,
        serviceId,
        slots: [slot],
      });

      const availableEmployees: IAvailableEmployees[] = [];

      slots.forEach(slot => {
        const availableAdditionalService = slot.available_services.find(
          availableAdditionalService =>
            availableAdditionalService.item_service_id === additionalService.id,
        );

        if (availableAdditionalService) {
          availableAdditionalService.available_employees?.forEach(employee => {
            if (
              employee.serviceId === serviceId &&
              employee.userId ===
                additionalService?.employees[0]?.public_user_id
            ) {
              availableEmployees.push(employee);
            }
          });
        }
      });

      const isOneSelected = availableEmployees.some(item => !item.isAvailable);

      const isAvailableSlot = isCountable
        ? bookedCount < count
        : isAllAvailable && isSlotWithEmployeeAvailable && !isOneSelected;

      return isAvailableSlot;
    });

    return availableSlots.length > 0;
  }, [additionalService, additionalServiceOrders, serviceId, slots]);

  return (
    <Root>
      <Typography
        component="h4"
        fontSize={16}
        fontWeight={500}
        color="text.primary"
        marginBottom="10px">
        {additionalService.name}
      </Typography>
      <Price>
        <Typography
          component="span"
          fontSize={16}
          fontWeight={600}
          color="text.accent">
          <AppAmount amount={Number(additionalService.price)} />
        </Typography>
        {isCountable ? (
          hasSomeSelected ? (
            <AppBtn size="s" onClick={onDeselect} filling="primary">
              <CheckMarkIcon width={18} height={18} />
            </AppBtn>
          ) : hasAvailableSlots ? (
            <AppBtn size="s" onClick={onToggleSelect} filling="secondary">
              <PlusIcon />
            </AppBtn>
          ) : (
            <AppBtn size="s" disabled filling="secondary">
              {t('OUT_OF_QUANTITY')}
            </AppBtn>
          )
        ) : (
          <AppCheckbox
            disabled={!hasAvailableSlots}
            isIndeterminate={!isAllSelected && hasSomeSelected}
            checked={hasSomeSelected}
            onChange={onToggleSelect}
            size="l"
          />
        )}
      </Price>
      {expanded && (
        <Slots>
          <Typography
            component="span"
            fontSize={16}
            fontWeight={500}
            color="text.primary"
            marginBottom="20px">
            {t('SELECT_ADDITIONAL_SERVICES_SLOT')}
          </Typography>
          {slots.map(slot => {
            const checked =
              additionalServiceOrders.findIndex(
                item =>
                  item.serviceId === serviceId &&
                  item.additionalServiceId === additionalService.id &&
                  item.slotFrom === slot.from,
              ) !== -1;

            const { bookedCount, count } = getAdditionalServiceCounts({
              additionalService,
              additionalServiceOrders,
              serviceId,
              slots: [slot],
            });

            const availableEmployees: IAvailableEmployees[] = [];

            slots.forEach(slot => {
              const availableAdditionalService = slot.available_services.find(
                availableAdditionalService =>
                  availableAdditionalService.item_service_id ===
                  additionalService.id,
              );

              if (availableAdditionalService) {
                availableAdditionalService.available_employees?.forEach(
                  employee => {
                    if (
                      employee.serviceId === serviceId &&
                      employee.slotFrom === slot.from &&
                      employee.userId ===
                        additionalService?.employees[0]?.public_user_id
                    ) {
                      availableEmployees.push(employee);
                    }
                  },
                );
              }
            });

            const isOneSelected = availableEmployees.some(
              item => !item.isAvailable,
            );

            const disabled = isCountable ? bookedCount >= count : isOneSelected;

            return (
              <SlotItem key={slot.from}>
                <AppSwitch
                  className="slot-switch"
                  disabled={disabled}
                  checked={checked}
                  onChange={onChangeCheckbox(slot)}
                  reversed
                  height={30}>
                  <TimeRange slot={slot} />{' '}
                  <Typography
                    component="span"
                    fontSize={16}
                    fontWeight={500}
                    color="text.tertiary">
                    {format(parseApiDate(slot.from), 'd MMMM yyyy, EEEEEE', {
                      locale,
                    })}
                  </Typography>
                </AppSwitch>
              </SlotItem>
            );
          })}
        </Slots>
      )}
    </Root>
  );
};
