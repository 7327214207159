import React, { useCallback, useLayoutEffect } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Paths } from 'routers/constants';
import { Typography } from '@mui/material';

import { publicApi } from 'store/api';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getOrderItemsWithServices, orderSlice } from 'store/slices/order';

import { useSafeParams } from 'hooks/useSafeParams';
import { Header } from 'components/Header';
import { SelectService } from 'features/service/SelectService';
import { AdditionalService } from 'features/additionalService';
import {
  ClientInformation,
  TClientInformation,
  getPaymentTypes,
} from 'features/order';
import { IAdditionalServiceOrder, IRentalItem, ITimeSlot } from 'types/types';
import { assertIsDefined } from 'utils/assertion';
import { isApiError, isFetchBaseQueryError } from 'utils/network';
import { isAdminRoute } from 'features/admin/utils/isAdmin';

import { SelectSlot } from '../SelectSlot';

import { StyledPageSelectSlot } from './PageSelectSlot.styles';

export const PageSelectSlot = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const step = useAppSelector(state => state.order.step);

  const selectedServices = useAppSelector(state => state.order.services);
  const selectedSlots = useAppSelector(state => state.order.slots);
  const selectedAdditionalServices = useAppSelector(
    state => state.order.additionalServiceOrders,
  );

  const { publicId } = useSafeParams();
  const { data } = publicApi.useGetUnitedDataQuery(
    publicId
      ? {
          public_id: publicId,
        }
      : skipToken,
  );
  const [makeOrder] = publicApi.useMakeOrderMutation();

  useLayoutEffect(() => {
    dispatch(orderSlice.actions.setStep('SELECT_SLOT'));
    dispatch(orderSlice.actions.setSlots([]));
    dispatch(orderSlice.actions.setServices([]));
    dispatch(orderSlice.actions.setAdditionalServiceOrders([]));
  }, [dispatch]);

  const onSelectSlot = useCallback(
    (slots: ITimeSlot[]) => {
      dispatch(orderSlice.actions.setSlots(slots));
      dispatch(orderSlice.actions.setStep('SELECT_SERVICE'));

      // Обнуляем выбранные сервисы если выбирают слот
      dispatch(orderSlice.actions.setServices([]));
      dispatch(orderSlice.actions.setAdditionalServiceOrders([]));
    },
    [dispatch],
  );

  const onSelectService = useCallback(
    (services: IRentalItem[]) => {
      dispatch(orderSlice.actions.setServices(services));
      dispatch(orderSlice.actions.setStep('SELECT_ADDITIONAL_SERVICE'));
      dispatch(orderSlice.actions.setAdditionalServiceOrders([]));
    },
    [dispatch],
  );

  const onSelectAdditional = useCallback(
    (additionalServices: IAdditionalServiceOrder[]) => {
      dispatch(
        orderSlice.actions.setAdditionalServiceOrders(additionalServices),
      );
      dispatch(orderSlice.actions.setStep('CLIENT_INFORMATION'));
    },
    [dispatch],
  );

  const onRemoveSlot = useCallback(
    (slot: ITimeSlot) => {
      dispatch(orderSlice.actions.removeSlot(slot));
    },
    [dispatch],
  );

  const onClientInformation = useCallback(
    async (values: TClientInformation) => {
      try {
        assertIsDefined(publicId);
        assertIsDefined(values.payment_type);

        const result = await makeOrder({
          public_id: publicId,
          email: values.email,
          first_name: values.first_name,
          last_name: values.last_name ?? '',
          comment: values.comment,
          phone: values.phone,
          payment_type_id: values.payment_type.value,
          captcha: '1234',
          orders: getOrderItemsWithServices({
            services: selectedServices,
            slots: selectedSlots,
            additionalServiceOrders: selectedAdditionalServices,
            remindTime: values.remind ? values.remindTime?.value : undefined,
          }),
        }).unwrap();

        if (isAdminRoute()) {
          navigate(
            `/${publicId}/${Paths.Admin}${Paths.Result}?rental_order_id=${result.data.result.rental_order_id}`,
            {
              replace: true,
            },
          );
        } else {
          navigate(
            `/${publicId}/${Paths.Result}?rental_order_id=${result.data.result.rental_order_id}`,
            {
              replace: true,
            },
          );

          if (result.data.result.payment_link) {
            window.location.href = result.data.result.payment_link;
          }
        }
      } catch (e) {
        if (isFetchBaseQueryError(e) && isApiError(e.data)) {
          toast.error(e.data.error?.message, {
            position: 'bottom-center',
          });
        } else {
          toast.error(t('BOOK_ERROR'), {
            position: 'bottom-center',
          });
        }
      }
    },
    [
      makeOrder,
      navigate,
      publicId,
      selectedAdditionalServices,
      selectedServices,
      selectedSlots,
      t,
    ],
  );

  if (!step || step === 'SELECT_SLOT') {
    return (
      <StyledPageSelectSlot>
        <SelectSlot
          onBackClick={() => navigate(`/${publicId}`)}
          defaultSlots={selectedSlots}
          onSubmit={onSelectSlot}
          services={[]} // ???
        />
      </StyledPageSelectSlot>
    );
  }

  if (step === 'SELECT_SERVICE') {
    return (
      <StyledPageSelectSlot>
        <Header
          onBackClick={() =>
            dispatch(orderSlice.actions.setStep('SELECT_SLOT'))
          }>
          <Typography
            component="h2"
            className="serviceCard__price"
            width="100%"
            textAlign="center"
            fontSize={20}
            fontWeight={600}
            color="text.secondary">
            {t('STEP_ONE')}
          </Typography>
        </Header>
        <SelectService onSubmit={onSelectService} slots={selectedSlots} />
      </StyledPageSelectSlot>
    );
  }

  if (step === 'SELECT_ADDITIONAL_SERVICE') {
    return (
      <StyledPageSelectSlot>
        <Header
          onBackClick={() =>
            dispatch(orderSlice.actions.setStep('SELECT_SERVICE'))
          }>
          <Typography
            component="h2"
            className="serviceCard__price"
            width="100%"
            textAlign="center"
            fontSize={20}
            fontWeight={600}
            color="text.secondary">
            {t('ADDITIONAL_SERVICES')}
          </Typography>
        </Header>
        <AdditionalService
          onSubmit={onSelectAdditional}
          services={selectedServices}
          slots={selectedSlots}
        />
      </StyledPageSelectSlot>
    );
  }

  if (step === 'CLIENT_INFORMATION') {
    const paymentTypesAll = data?.data.project_info.payment_types || [];
    const paymentTypes = getPaymentTypes(paymentTypesAll, selectedSlots);

    return (
      <StyledPageSelectSlot>
        <Header
          onBackClick={() =>
            dispatch(orderSlice.actions.setStep('SELECT_ADDITIONAL_SERVICE'))
          }>
          <Typography
            component="h2"
            className="serviceCard__price"
            width="100%"
            textAlign="center"
            fontSize={20}
            fontWeight={600}
            color="text.secondary">
            {t('INFO_ABOUT_YOU')}
          </Typography>
        </Header>
        <ClientInformation
          paymentTypes={paymentTypes}
          onSubmit={onClientInformation}
        />
      </StyledPageSelectSlot>
    );
  }

  return null;
};
