import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import {
  IAdditionalServiceOrder,
  IOrderWIthItemServices,
  IOrderItem,
  IRentalItem,
  ITimeSlot,
} from 'types/types';
import { parseApiDate } from 'utils/parseApiDate';

export type TOrderStep =
  | 'SELECT_SERVICE'
  | 'SELECT_SLOT'
  | 'SELECT_ADDITIONAL_SERVICE'
  | 'CLIENT_INFORMATION';

export interface IOrderState {
  step: TOrderStep | null;
  services: IRentalItem[];
  slots: ITimeSlot[];
  additionalServiceOrders: IAdditionalServiceOrder[];
}

const initialState: IOrderState = {
  step: null,
  services: [],
  slots: [],
  additionalServiceOrders: [],
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<TOrderStep>) => {
      state.step = action.payload;
    },
    setServices: (state, action: PayloadAction<IRentalItem[]>) => {
      state.services = action.payload;
    },
    setSlots: (state, action: PayloadAction<ITimeSlot[]>) => {
      const result = [...action.payload];

      result.sort(
        (a, b) =>
          parseApiDate(a.from).getTime() - parseApiDate(b.from).getTime(),
      );

      state.slots = result;
    },
    setAdditionalServiceOrders: (
      state,
      action: PayloadAction<IAdditionalServiceOrder[]>,
    ) => {
      state.additionalServiceOrders = action.payload;
    },
    removeSlot: (state, action: PayloadAction<ITimeSlot>) => {
      state.slots = state.slots.filter(
        slot => slot.from !== action.payload.from,
      );

      state.additionalServiceOrders = state.additionalServiceOrders.filter(
        item => item.slotFrom !== action.payload.from,
      );
    },
  },
});

export default orderSlice.reducer;

/**
 * Соединяет между собой услуги и слоты и создает массив заказов.
 * Проверяет доступность услуги у слота.
 */
export const getOrderItemsWithServices = ({
  services,
  slots,
  additionalServiceOrders,
  remindTime,
}: {
  services: IRentalItem[];
  slots: ITimeSlot[];
  additionalServiceOrders: IAdditionalServiceOrder[];
  remindTime?: string;
}): IOrderWIthItemServices[] => {
  const result: IOrderWIthItemServices[] = [];

  services.forEach(service => {
    slots.forEach(slot => {
      if (slot.available_items.includes(service.id)) {
        const additionalServicesIds = additionalServiceOrders
          .filter(
            item =>
              item.serviceId === service.id && item.slotFrom === slot.from,
          )
          .map(item => {
            return {
              item_service_id: item.additionalServiceId,
              public_user_id:
                (item?.employees && item?.employees[0]?.public_user_id) || '0',
            };
          });

        result.push({
          rental_item_id: service.id,
          from: slot.from,
          to: slot.to,
          ordered_item_services: additionalServicesIds,
          comment: undefined,
          params: {
            custom_interval: remindTime,
          },
        });
      }
    });
  });

  return result;
};

export const getOrderItems = ({
  services,
  slots,
  additionalServiceOrders,
  remindTime,
}: {
  services: IRentalItem[];
  slots: ITimeSlot[];
  additionalServiceOrders: IAdditionalServiceOrder[];
  remindTime?: string;
}): IOrderItem[] => {
  const result: IOrderItem[] = [];

  services.forEach(service => {
    slots.forEach(slot => {
      if (slot.available_items.includes(service.id)) {
        const additionalServicesIds = additionalServiceOrders
          .filter(
            item =>
              item.serviceId === service.id && item.slotFrom === slot.from,
          )
          .map(item => item.additionalServiceId);

        result.push({
          rental_item_id: service.id,
          from: slot.from,
          to: slot.to,
          ordered_item_service_ids: additionalServicesIds,
          comment: undefined,
          params: {
            custom_interval: remindTime,
          },
        });
      }
    });
  });

  return result;
};
