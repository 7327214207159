import { ITimeSlot } from 'types/types';

export const toggleModifiedSlots = (
  slots: ITimeSlot[],
  additionalServiceId: string,
  slotFrom: string,
  serviceId: string,
  isAvailable: boolean,
) => {
  return slots.map(curSlot => {
    if (curSlot.from === slotFrom) {
      return {
        ...curSlot,
        available_services: curSlot.available_services.map(service => {
          const matchingService =
            additionalServiceId === service.item_service_id;

          if (matchingService) {
            return {
              ...service,
              available_employees: service.available_employees?.map(
                availableService => {
                  if (
                    availableService.serviceId !== serviceId &&
                    availableService.slotFrom === slotFrom
                  ) {
                    return {
                      ...availableService,
                      isAvailable,
                    };
                  }
                  return availableService;
                },
              ),
            };
          }

          return service;
        }),
      };
    }
    return curSlot;
  });
};
